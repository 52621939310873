.navbar-profile-header {
  position: relative;
  padding: 0 15px;

  &.notin-profile-panel {
    border-bottom: 1px solid #f2f2f2;

    .navbar-inner {
      margin: auto;
    }
  }

  &.app-layout {
    max-width: 50%;
    float: right;
    border: 0;
    margin: 10px 0 0;
  }

  .navbar-inner {
    padding: 10px 0;
    width: 100%;
    max-width: 1375px;
  }

  a {
    &.navbar-logo {
      margin: 0;
      padding: 0;
      display: block;
      float: left;
      width: auto;
      height: 35px;

      img {
        display: block;
        width: auto;
        height: 35px;
      }
    }
  }

  /* Header Navigation Tooggler */
  button {
    &.nav-toggler-personal {
      position: absolute;
      top: 13px;
      right: 15px;
      z-index: 9;
      margin: 0;
      padding: 0;
      display: none;
      width: 30px;
      height: 30px;
      line-height: 28px;
      text-align: center;
      cursor: pointer;
      border: 1px solid $color-charcoal;
      background-color: transparent;

      @include rounded(2px);
      @include transition(
                      color 0.10s ease-in-out,
                      border-color 0.10s ease-in-out,
                      background-color 0.10s ease-in-out
      );

      /* Active/Hover States */
      &.active,
      &:hover {
        color: $color-green;
        border-color: $color-charcoal;
        background-color: $color-charcoal;
      }
    }
  }

  /* Profile Header Navigation */
  .header-nav-wrapper {
    float: right;

    ul {
      &.navbar-nav {
        float: right;
        .username-avatar{
          position: relative;
          display: inline-block;
          > .dropdown-content {
            display: none;
            position: absolute;
            background-color: #fff;
            min-width: 160px;
            box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
            z-index: 1;
            right: 0;
            border: 1px solid #d1d5da;
            padding: 0;

            > a {
              color: black;
              padding: 6px 16px;
              text-decoration: none;
              display: block;
              font-family: sans-serif;
              line-height: 20px;

              &:hover {
                background-color: #d1d5da;
              }

              &.item {
                &.active {
                  color: #fff;
                  text-decoration: none;
                  background-color: $color-green;
                }
              }

              &.border_bottom {
                border-bottom: 1px solid #d1d5da;
                margin-bottom: 5px;
              }
              &.border_top {
                border-top: 1px solid #d1d5da;
                margin-top: 5px;
              }

              &.header_hover_menu_tab_my_user_guide {
                margin-top: 5px;
                padding-top: 6px;
                border-top: 1px solid #d1d5da;
              }
            }
          }

          &:hover .dropdown-content {
            display: block;
          }
        }
      }
      > li {
        display: block;
        float: left;

        a {
          display: block;
          color: $color-black;

          @include transition(color 0.10s ease-in-out);

          /* Hover State */
          &:hover {
            color: $color-green;
          }
        }

        /* Username and Avatar */
        &.username-avatar {
          > li.username-avatar {
            position: relative;
          }
          a {
            &::after {
              clear: both;
              margin: 0;
              padding: 0;
              display: table;
              width: 0;
              height: 0;
              font-size: 0;
              line-height: 0;
              content: ' ';
              overflow: hidden;
              visibility: hidden;
              *zoom: 1;
            }
            > span {
              display: block;

              > img {
                /*display: block;
                float: right;*/
                display: inline-block;
                float: none;
                width: 30px;
                height: 30px;
                border: 0 none;
                margin-right: 0.25rem;
                border-radius: 100%;
                max-width: 100%;
                vertical-align: middle;
              }
              > span {
                /*position: absolute;
                top: -1px;
                left: -1px;
                margin: -1px 0 0 -1px;
                display: block;
                float: left;
                width: 1px;
                height: 1px;
                font-size: 1px;

                @include opacity(0.000001);*/
                display: inline-block;
                float: none;
                vertical-align: middle;
              }
            }
          }
        }

        /* Settings */
        &.settings {
          padding-left: 10px;

          > a {
            line-height: 30px;
          }
        }

        /* Login */
        &.login {
          padding-left: 10px;

          > a {
            line-height: 30px;
          }
        }

        /* Register */
        &.register {
          padding-left: 10px;

          > a {
            line-height: 30px;
          }
        }

        /* Logout */
        &.logout {
          padding-left: 10px;

          > a {
            line-height: 30px;
          }
        }

        /* Instructions */
        &.instructions {
          display: block;
          padding: 10px 0;
          margin-right: 30px;

          > a {
            &::before {
              font-family: 'Font Awesome Solid';

              /* https://fontawesome.com/icons/question-circle?style=solid */
              content: '\f059';
            }
          }
        }
      }
    }
  }
}

/* Media Queries */
/* 800px */
@media screen and (max-width: 800px){
  .navbar-profile-header {

    /* Header Navigation Tooggler */
    button {
      &.nav-toggler-personal {
        display: block;
      }
    }

    .header-nav-wrapper {
      position: absolute;
      top: 100%;
      right: 0;
      z-index: 8;
      display: none;
      width: 100%;
      max-width: 200px;
      border: 1px solid rgba($color-black, 0.10);
      border-width: 1px 0 1px 1px;
      background-color: $color-white;

      border-bottom-left-radius: 3px;

      ul {
        &.navbar-nav {
          display: block;
          float: none;

          > li {
            padding: 0 !important;
            display: block;
            float: none;

            > a {
              padding: 0 15px;
              display: block;
              line-height: 30px;
              text-align: right;
              border-bottom: 1px solid rgba($color-black, 0.05);

              i { display: none; }

              span {
                &.dn-ns {
                  display: block;
                }
              }
            }

            &:last-child {
              > a {
                border-width: 0;
              }
            }

            &.username-avatar {
              > a {
                > span {
                  > img { display: none; }
                  > span {
                    position: relative;
                    top: auto;
                    right: auto;
                    bottom: auto;
                    left: auto;
                    margin: 0;
                    padding: 0;
                    float: none;
                    width: auto;
                    height: auto;
                    font-size: inherit;

                    @include opacity(1.00);
                  }
                }
              }
            }
          }
        }
      }
    }

    /* Active Status */
    button {
      &.nav-toggler-personal {
        &.active {
          + .header-nav-wrapper { display: block; }
        }
      }
    }
  }
}