/* Margin/Padding Amounts */
$spaceamounts		: (0,5,10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95,100);
$spacesides			: (all,top,right,bottom,left);

/* Browser Vendor Prefixes */
$vendors			: "-webkit-", "-moz-", "-ms-", "-o-", "";

/* Font Awesome */
$fabrands           : 'Font Awesome 6 Brands', Arial, Helvetica, Sans-serif;
$fathin             : 'Font Awesome 6 Thin', Arial, Helvetica, Sans-serif;
$falight            : 'Font Awesome 6 Light', Arial, Helvetica, Sans-serif;
$faregular          : 'Font Awesome 6 Regular', Arial, Helvetica, Sans-serif;
$faregularsharp     : 'Font Awesome 6 Regular Sharp', Arial, Helvetica, Sans-serif;
$fasolid            : 'Font Awesome 6 Solid', Arial, Helvetica, Sans-serif;
$fasolidsharp       : 'Font Awesome 6 Solid Sharp', Arial, Helvetica, Sans-serif;

// Body
$body-bg: #f9f9f9;

// Typography
$font-family-sans-serif: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
$font-size-base: 0.875rem;
$line-height-base: 1.6;
$color: hsl(0, 0%, 13%);
$gray-color: #ccc;
$gray-light-color: #d1d5da;
$gray-lighter-color: #d9d9d9;
$gray-lightest-color: rgba(0, 0, 0, 0.1);
$darkest-red-color: #cb2431;

/* Font Stacks */
$font1        		: 'Lato', Arial, Helvetica, sans-serif;
$font2        		: 'RobotoSlab', 'Georgia', 'Times New Roman', serif;
$font3        		: Arial, Helvetica, sans-serif;