:root {
    --r1color-charcoal  : #4f4f51;
    --r1color-darkgray  : #333;
    --r1color-green     : #76bc43;
    --r1color-white     : #fff;
    --r1color-black     : #000;
}

$color-charcoal         : #4f4f51;
$color-darkgray         : #333;
$color-green            : #76bc43;
$color-green-2          : #43b02a;
$color-green-3          : #6ebe46;
$color-white            : #fff;
$color-black            : #000;

$color-lightgray-1      : #f9f9f9;
$color-lightgray-2      : #eee;
$color-lightgray-3      : #ddd;
$color-lightgray-4      : #ccc;
$color-lightgray-5      : #bbb;
$color-lightgray-6      : #aaa;

$color-green-r1         : #43b02a; /* R1 Green */
$color-white 	        : #fff;

$color-gray-f           : #7c7a83;
$color-gray-e           : #eee;
$color-gray-d           : #ddd;
$color-gray-c           : #ccc;
$color-gray-b           : #bbb;
$color-gray-a           : #aaa;
$color-gray-9           : #999;
$color-gray-8           : #888;
$color-gray-7           : #777;
$color-gray-6           : #666;
$color-gray-5           : #555;
$color-gray-4           : #444;
$color-gray-3           : #333;
$color-gray-2           : #222;
$color-gray-1           : #111;
